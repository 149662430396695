
import {computed, defineComponent, ref} from 'vue'
import {useAppStore} from "@/store/app/AppStore";
import firebase from "firebase/app";
import {TYPE} from "vue-toastification";
import InputField from "@/components/utils/input/InputField.vue";
import {InputFieldType} from "@/types";
import ReAuthModal from "@/components/utils/modal/ReAuthModal.vue";

export default defineComponent({
  name: "UserPasswordChange",
  components: {ReAuthModal, InputField},
  setup() {
    const reAuthModal = ref(false)
    const appStore = useAppStore()
    const password = ref<string | undefined>(undefined)
    const updatePassword = (param: { user: firebase.User, newPassword: string }): Promise<void> => {
      return new Promise(resolve => {
        const loadingID = appStore.addLoading()
        const {user, newPassword} = param
        user.updatePassword(newPassword)
            .then(() => {
              appStore.showToast(TYPE.SUCCESS, 'Parool edukalt muudetud')
              password.value = undefined
              appStore.removeLoading(loadingID)
              resolve()
            })
            .catch((error) => {

              if (error.code === 'auth/requires-recent-login') {
                reAuthModal.value = true
                appStore.removeLoading(loadingID)
                resolve()
              } else {
                appStore.showToast(TYPE.ERROR, error.message)
                appStore.removeLoading(loadingID)
                resolve()
              }
            })
      })
    }

    const onSave = async () => {
      const user = firebase.auth().currentUser
      if (!user) return
      if (password.value) await updatePassword({user, newPassword: password.value})
    }
    const passwordValid = computed(() => {
      if (!password.value) return true;
      else return password.value.length >= 6;
    });

    const onReAuthModalClose = async (success: boolean) => {
      if (success) await onSave()
      else {
        appStore.showToast(TYPE.WARNING, 'Parooli muutmine katkestatud')
        password.value = undefined
      }
      reAuthModal.value = false
    }
    return {password, onSave, InputFieldType, passwordValid, reAuthModal, onReAuthModalClose}
  }
})
