
import {defineComponent, onMounted, ref} from 'vue'
import InputField from "@/components/utils/input/InputField.vue";
import {useAppStore} from "@/store/app/AppStore";
import firebase from "firebase";
import {TYPE} from "vue-toastification";
import {useUserStore} from "@/store/user/UserStore";

export default defineComponent({
  name: "UserNameChange",
  components: {InputField},
  setup() {
    const userStore = useUserStore()

    const appStore = useAppStore()
    const name = ref<string | undefined>()
    onMounted(() => {
      const user = firebase.auth().currentUser
      if (!user) return
      name.value = user.displayName || undefined
    })

    const updateName = (param: { user: firebase.User, newName: string }): Promise<void> => {
      return new Promise(resolve => {
        const loadingID = appStore.addLoading()
        const {user, newName} = param
        user.updateProfile({displayName: newName})
            .then(() => {
              userStore.setName(firebase.auth().currentUser?.displayName || undefined)
              appStore.showToast(TYPE.SUCCESS, 'Nimi edukalt muudetud')
              appStore.removeLoading(loadingID)
              resolve()
            })
            .catch(error => {
              appStore.showToast(TYPE.ERROR, error.message)
              appStore.removeLoading(loadingID)
              resolve()
            })
      })
    }

    const onSave = async () => {
      const user = firebase.auth().currentUser
      if (!user) return
      if (user.displayName !== name.value && !!name.value) await updateName({user, newName: name.value})
    }
    return {name, onSave}
  }
})
