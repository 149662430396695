
import {defineComponent, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import firebase from "firebase/app";
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";


export default defineComponent({
  name: "ReAuthModal",
  emits: {
    onClose: (success: unknown) => typeof success === 'boolean'
  },
  components: {ModalWrapper},
  setup(_, {emit}) {
    const isOpen = ref(true)
    const onClose = (success = false) => emit('onClose', success)
    const appStore = useAppStore();

    const password = ref<string | null>(null);
    const tryLogin = async () => {
      const user = firebase.auth().currentUser;
      const loadingID = appStore.addLoading()
      if (!user || !user.email || !password.value) return
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, password.value);
        await user.reauthenticateWithCredential(credential)
        appStore.showToast(TYPE.SUCCESS, 'Sisse logimine õnnestus!')
        appStore.removeLoading(loadingID)
        onClose(true)
      } catch (e: any) {
        appStore.showToast(TYPE.ERROR, e.code);
        appStore.removeLoading(loadingID)
      }

    };

    const onLoginAttempt = async () => {
      if (password.value) await tryLogin();
    };
    return {isOpen, onClose, onLoginAttempt, password}
  }
})
