
import {defineComponent} from 'vue'

import UserEmailChange from "@/components/settings/UserEmailChange.vue";
import UserNameChange from "@/components/settings/UserNameChange.vue";
import UserPhoneChange from "@/components/settings/UserPhoneChange.vue";
import UserPasswordChange from "@/components/settings/UserPasswordChange.vue";

export default defineComponent({
  components: {UserPasswordChange, UserPhoneChange, UserNameChange, UserEmailChange},
  setup() {

    return {}
  }
})
