
import {defineComponent, onMounted, ref} from 'vue'
import InputField from "@/components/utils/input/InputField.vue";
import firebase from "firebase";
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";
import {InputFieldType} from "@/types";
import {useUserStore} from "@/store/user/UserStore";
import ReAuthModal from "@/components/utils/modal/ReAuthModal.vue";

export default defineComponent({
  name: "UserEmailChange",
  components: {ReAuthModal, InputField},
  setup() {
    const userStore = useUserStore()
    const appStore = useAppStore()
    const email = ref<string | undefined>()

    const reAuthModal = ref(false)

    const setEmail = () => {
      const user = firebase.auth().currentUser;
      email.value = user?.email || undefined
    }

    onMounted(() => setEmail())

    const updateEmail = (param: { user: firebase.User, newEmail: string }): Promise<void> => {
      return new Promise((resolve) => {
        const loadingID = appStore.addLoading()
        const {user, newEmail} = param
        user.updateEmail(newEmail).then(() => {
          userStore.setName(firebase.auth().currentUser?.email || undefined)
          appStore.showToast(TYPE.SUCCESS, 'E-mail edukalt muudetud')
          appStore.removeLoading(loadingID)
          resolve()
        }).catch((error) => {
          if (error.code === 'auth/requires-recent-login') {
            reAuthModal.value = true
            appStore.removeLoading(loadingID)
            resolve()
          } else {
            appStore.showToast(TYPE.ERROR, error.message)
            appStore.removeLoading(loadingID)
            resolve()
          }

        });
      })
    }

    const onSave = async () => {
      const user = firebase.auth().currentUser;
      if (!user) return
      if (user.email !== email.value && email.value) await updateEmail({user, newEmail: email.value})
    }

    const onReAuthModalClose = async (success: boolean) => {
      if (success) await onSave()
      else {
        appStore.showToast(TYPE.WARNING, 'E-maili muutmine katkestatud')
        setEmail()
      }
      reAuthModal.value = false
    }

    return {email, onSave, InputFieldType, reAuthModal, onReAuthModalClose}
  }
})
