
import {computed, defineComponent, onMounted, ref} from 'vue'

import firebase from "firebase";

import InputField from "@/components/utils/input/InputField.vue";
import {useUserStore} from "@/store/user/UserStore";

export default defineComponent({
  name: "UserPhoneChange",
  components: {InputField},
  setup() {
    const userStore = useUserStore()

    const phone = ref<string | undefined>()
    onMounted(() => {
      const user = firebase.auth().currentUser
      if (!user) return
      phone.value = user.phoneNumber || undefined;
      // (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
    })

    const updatePhone = async (phone: string) => {
      await userStore.updateAuthUserPhone(phone)
      // const {user, newPhone} = param
      // var appVerifier = new firebase.auth.RecaptchaVerifier(
      //     "recaptcha-container",
      //     {
      //       size: "invisible"
      //     }
      // );

      // var provider = new firebase.auth.PhoneAuthProvider();
      // provider.verifyPhoneNumber(newPhone, appVerifier)
      //     .then(function (verificationId) {
      //       const verificationCode = window.prompt('Please enter the verification ' +
      //           'code that was sent to your mobile device.');
      //       const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode || '');
      //       user.updatePhoneNumber(phoneCredential);
      //     })
      //     .then((result) => {
      //       // Phone credential now linked to current user.
      //       // User now can sign in with new phone upon logging out.
      //       console.log(result);
      //     })
      //     .catch((error) => {
      //       // Error occurred.
      //       console.log(error);
      //     });
      // return new Promise( resolve => {
      // const loadingID = appStore.addLoading()
    }

    const onSave = async () => {
      const user = firebase.auth().currentUser
      if (!user) return
      if (user.phoneNumber !== phone.value && !!phone.value) await updatePhone(phone.value)
    }

    const isPhoneValid = computed(() => {
      if (!phone.value) return true;
      const regEx = /^\+[1-9]\d{1,14}$/;
      return regEx.test(phone.value);
    });
    return {phone, onSave, isPhoneValid}
  }
})
